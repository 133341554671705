<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import PointsEarnedModal from '@/components/Loyalty/PointsEarnedModal';
import TnC from '@/components/Themes/dayonedayone/Loyalty/TnC';
import dayjs from 'dayjs';

export default {
  name: 'Signup',
  props: ['visible'],
  components: {
    PointsEarnedModal,
    TnC,
  },
  data() {
    return {
      countryCode: process.env.VUE_APP_COUNTRY_CODE,
      loadedAt: null,
      signupPoints: 0,
      registerForm: {
        merchantId: 0,
        tnc: false,
      },
      attrs: [{
        key: 'selected',
        highlight: {
          class: 'vc-selected',
        },
        dates: new Date(),
      }],
      modelConfig: {
        timeAdjust: '00:00:00',
      },
      masks: {
        input: 'DD/MM/YYYY',
      },
      isLoading: false,
      show: false,
      error: null,
    };
  },
  computed: {
    ...mapState('customer', ['customer']),
    ...mapState('loyalty', {
      loyaltyCustomer: (state) => state.customer,
    }),
    ...mapState('merchant', {
      profile: (state) => state.profile,
    }),
    ...mapGetters('client', {
      name: 'getName',
    }),
  },
  methods: {
    ...mapActions('data', ['createDataAnalyticEvent']),
    ...mapActions('loyalty', {
      createLoyalty: 'create',
      getLoyaltyCustomer: 'getLoyaltyCustomer',
    }),
    backToHome() {
      window.location.href = '/';
    },
    async cancel() {
      this.$gtag.event('sign_up_cancel', {
        event_category: 'interaction',
        event_label: 'Sign up (Cancel)',
        event_value: `Email: ${this.registerForm.email}, Contact No: ${this.registerForm.contactNo}`,
      });

      this.$router.push({ name: 'home' });
    },
    async signup() {
      this.isLoading = true;

      if (!this.registerForm.tnc) {
        this.error = 'Please tick to agree the terms & conditions.';
        setTimeout(() => this.error = null, 5000);
        this.isLoading = false;
        return;
      }

      try {
        this.$gtag.event('sign_up', {
          event_category: 'engagement',
          event_label: 'Sign up',
          event_value: `Email: ${this.registerForm.email}, Contact No: ${this.registerForm.contactNo}`,
        });
        
        window.scrollTo(0,0);

        // join loyalty program
        this.signupPoints = (await this.createLoyalty({
          merchantId: this.profile.merchant_id,
        }))?.points ?? 0;

        this.createDataAnalyticEvent({
          event: 'join-success',
          data: {
            hqMerchantId: this.profile.hq_merchant_id,
            merchantId: this.profile.merchant_id,
            refTable: 'client',
            refId: this.customer.phoneNo,
            category: 'engagement',
            label: 'Time Used',
            value: dayjs().diff(this.loadedAt, 's'),
          },
        });

        await this.getLoyaltyCustomer({});

        this.isLoading = false;
      } catch (e) {
        console.log(e.message);
        this.isLoading = false;
        this.error = e.message;
        setTimeout(() => (this.error = null), 5000);
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.prevRoute = from);
  },
  async mounted() {
    // if (this.loyaltyCustomer) {
    //   this.$router.push({ name: 'home' });
    // }

    this.loadedAt = dayjs();
  },
};
</script>
<template>
  <v-sheet
    class="signup-header-sheet mx-auto pt-3"
    elevation="0"
    v-if="loyaltyCustomer"
  >
    <v-row class="m-0 py-2">
      <v-col cols="12">
        <h4 class="text-center white--text font-weight-bold">Welcome!</h4>
        <h2 class="text-center tertiary--text font-weight-bold mt-4">{{ name }}</h2>
        <points-earned-modal
          :points="signupPoints"
          :image-height="'45vh'"
          :margin-top="20"
          @ok="backToHome()"
          v-if="signupPoints > 0"
        ></points-earned-modal>
      </v-col>
    </v-row>
  </v-sheet>
  <v-sheet 
    class="signup-body-sheet mx-auto pt-6"
    elevation="0"
    v-else
  >
    <v-card color="#f7efe8" elevation="0">
      <v-card-title class="justify-center h4 font-weight-bold pb-3">
        <span style="color:#122944;">Join</span> <span class="tertiary--text ml-2">as member</span>
      </v-card-title>
      <v-card-text class="text-center pb-3">
        <v-row class="px-3">
          <v-col class="pl-3 pr-4 pb-0" cols="12">
            <TnC></TnC>
          </v-col>
        </v-row>
        <v-row class="px-3">
          <v-col cols="12" class="d-flex justify-content-center px-5 pt-0 pb-1">
            <v-checkbox
              v-model="registerForm.tnc"
              class="text-caption font-weight-6 mt-0 tnc"
              hide-details
              style="z-index:1;"
            >
              <template #label>
                I agreed with the terms & conditions above
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
        <v-row class="px-3">
          <v-col cols="6" class="pl-10">
            <v-btn
              :disabled="isLoading || loyaltyCustomer"
              class="signup-btn-cancel text-caption font-weight-6 white--text py-4"
              block
              @click="cancel()"
              >Cancel</v-btn
            >
          </v-col>
          <v-col cols="6" class="pr-10">
            <v-btn
              :disabled="isLoading || loyaltyCustomer"
              color=""
              class="signup-btn-submit text-caption font-weight-6 white--text py-4"
              block
              @click="signup()"
              >Join</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-alert
      v-if="error"
      class="alert-global text-body-2 white--text p-2"
      type="error"
      transition="fade-transition"
      dense
      >{{ error }}</v-alert
    >
  </v-sheet>
</template>
<style scoped>
.signup-body-sheet {
  background-color: #f7efe8;
}

.signup-header-sheet, .signup-success-sheet {
  background-color: var(--v-primary);
}

.signup-body-sheet /deep/ .theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
  border: none !important;
  background: #f0d2b7 !important;
  z-index: 1 !important;
}

.signup-body-sheet /deep/ .tnc .v-label {
  font-size: 12px !important;
  font-weight: bold;
  color: var(--v-dark);
  margin-top: 7px;
}

.signup-body-sheet /deep/ .signup-btn-cancel, 
.signup-body-sheet /deep/ .signup-btn-submit {
  background-color: var(--v-tertiary) !important;
  color: #fff !important;
  z-index: 1;
}

.register-form ::placeholder {
  color: var(--v-dark) !important;
  font-size: 12px;
  font-weight: 600;
}

.alert-global {
  position: fixed;
  z-index: 999;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(90vw - 48px);
}

@media screen and (min-width: 1024px) {
  .alert-global {
    width: 326px;
  }
}
</style>